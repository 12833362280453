<script setup>
// layout
import Layout from "../../layouts/main.vue";

// import thư viện
import mushroom from "cem-primary-api";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";
import { FormInstance } from "element-plus";
import toastr from "toastr";

import { langWeb } from "../../stores/lang";
const lang_web = langWeb()
const lang = ref(lang_web.showLang.toLocaleUpperCase())
// data khai báo
import MethodService from "../../service/MethodService";
import Data from "./dataFormCustomer";

const ruleFormRef = ref(FormInstance);
const dataForm = reactive({
  value: MethodService.copyObject(Data.dataForm),
});
const loadingBtn = ref(false);
const validateFlag = ref(false)
const dataService = reactive({ value: [] });
const dataTables = reactive({ value: [] });
const dataTablesConfig = reactive({ value: [] });
const checkDisabled = ref([
  {
    value: true,
    name: LanguageService?.lang?.t_yes ?? "t_yes",
  },
  {
    value: false,
    name: LanguageService?.lang?.t_no ?? "t_no",
  },
]);

// pinia store
import { onMounted, reactive } from "vue";

const router = useRoute();
const routerPush = useRouter();

// validate form
import Validate from "../../service/ValidService";
import LanguageService from "../../service/LanguageService";
import { watch } from "vue";

const rulesForm = reactive({
  type: [Validate.required],
  col_name: [Validate.required],
  col_position: [Validate.required, Validate.checkNumberFloatNotNegative],
  field_name: [Validate.required],
  table_config_id: [Validate.required],
  thresh_check: [Validate.required],
  disabled: [Validate.required],
  search_enable: [Validate.required],
  service_position: [Validate.checkNumberFloatNotNegative],
});

const getCustomerSummary = async () => {
  if (router.query.id) {
    try {
      const response = await mushroom.customer_summary.findByIdAsync({
        id: router.query.id,
      });
      dataForm.value = response.result;

      if (response.result.service_id) {
        dataTables.value = dataTablesConfig.value.filter(
          (table) => table.service_id === response.result.service_id
        );
      }
    } catch (e) {
      MethodService.showError(e.code);
    }
  }
};

const getService = async () => {
  try {
    const response = await mushroom.service.getAllAsync(
      { limit: 100 },
      {
        enabledCache: false,
      }
    );
    if (response.result) {
      dataService.value = response.result;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getTable = async () => {
  try {
    const response = await mushroom.table_config.getAllAsync(
      { limit: 100 },
      {
        enabledCache: false,
      }
    );
    if (response.result) {
      dataTables.value = response.result;
      dataTablesConfig.value = response.result;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const addNewCustomer = async (formEl) => {
  validateFlag.value = true
  if (!formEl) return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    if (valid) {
      const customer_summary_object = {
        type: dataForm.value.type,
        service_id: dataForm.value.service_id,
        service_position: dataForm.value.service_position,
        col_name: dataForm.value.col_name,
        col_position: dataForm.value.col_position,
        field_name: dataForm.value.field_name,
        table_config_id: dataForm.value.table_config_id,
        thresh_check: dataForm.value.thresh_check,
        disabled: dataForm.value.disabled,
        search_enable: dataForm.value.search_enable,
        is_identity: dataForm.value.is_identity,
        sortable: dataForm.value.sortable,
        default_sort_type: dataForm.value.default_sort_type
          ? dataForm.value.default_sort_type
          : null,
      };

      if (dataForm.value.type == "session")
        customer_summary_object.where_statement =
          dataForm.value.where_statement;

      try {
        const newId = await mushroom.customer_summary.createAsync(
          customer_summary_object
        );
        if (newId) {
          toastr.success(LanguageService[lang.value]?.toastr_add_customer_success ?? "toastr_add_customer_success");
        }
        routerPush.go(-1);
      } catch (error) {
        // if (error.meta.field === "$unique_key") {
        //     toastr.error("Tên bảng đã tồn tại!");
        // }
        toastr.error(LanguageService[lang.value]?.toastr_add_customer_fail ?? "toastr_add_customer_fail");
        console.error("Có lỗi: %o", error);
      } finally {
        setTimeout(() => {
          loadingBtn.value = false;
        }, 2000);
      }
    } else {
      loadingBtn.value = false;
      return false;
    }
  });
};

const updateCustomer = async (formEl) => {
  validateFlag.value = true
  if (!formEl) return;
  formEl.validate(async (valid) => {
    loadingBtn.value = true;
    if (valid) {
      const customer_summary_object = {
        id: router.query.id,
        type: dataForm.value.type,
        service_id: dataForm.value.service_id,
        service_position: dataForm.value.service_position,
        col_name: dataForm.value.col_name,
        col_position: dataForm.value.col_position,
        field_name: dataForm.value.field_name,
        table_config_id: dataForm.value.table_config_id,
        thresh_check: dataForm.value.thresh_check,
        disabled: dataForm.value.disabled,
        search_enable: dataForm.value.search_enable,
        is_identity: dataForm.value.is_identity,
        sortable: dataForm.value.sortable,
        default_sort_type: dataForm.value.default_sort_type
          ? dataForm.value.default_sort_type
          : null,
      };
      if (dataForm.value.type == "session")
        customer_summary_object.where_statement =
          dataForm.value.where_statement;

      try {
        const response = await mushroom.customer_summary.partialUpdateAsync(
          customer_summary_object
        );
        if (response.result == router.query.id) {
          toastr.success(LanguageService[lang.value]?.toastr_edit_customer_success ?? "toastr_edit_customer_success");
        } else toastr.warning(LanguageService[lang.value]?.toastr_not_change_warning ?? "toastr_not_change_warning");
        routerPush.go(-1);
      } catch (error) {
        console.error("Có lỗi: %o", error);
        toastr.error(LanguageService[lang.value]?.toastr_edit_customer_fail ?? "toastr_edit_customer_fail");
      } finally {
        setTimeout(() => {
          loadingBtn.value = false;
        }, 2000);
      }
    } else {
      loadingBtn.value = false;
      return false;
    }
  });
};

const handleChangeInput = () => {
  if (
    dataForm.value.default_sort_type === 0 ||
    dataForm.value.default_sort_type === ""
  ) {
    dataForm.value.default_sort_type = undefined;
  }
};

const changeService = async (e) => {
  if (dataForm.value.service_id) {
    dataTables.value = dataTablesConfig.value.filter(
      (table) => table.service_id === dataForm.value.service_id
    );
  }

  await getInfo(e);
};
const getInfo = async (idService) => {
  try {
    const response = await mushroom.customer_summary.listAsync({
      filters: ["service_id=" + idService, "type=session"],
      fields: "id,service_id,service_position,table_config_id,where_statement",
      limit: 1,
    });
    if (response.result?.length > 0) {
      dataForm.value.table_config_id = response.result[0].table_config_id;
      dataForm.value.where_statement = response.result[0].where_statement;
    } else {
      dataForm.value.table_config_id = undefined;
      dataForm.value.where_statement = undefined;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const resetService = () => {
  dataTables.value = dataTablesConfig.value;
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    checkDisabled.value = [
      {
        value: true,
        name: LanguageService?.lang?.t_yes ?? "Yes",
      },
      {
        value: false,
        name: LanguageService?.lang?.t_no ?? "No",
      },
    ];
    if (validateFlag.value == true) {
      rulesForm.value = MethodService.copyObject(rulesForm);
    }
  }
);

onMounted(async () => {
  dataForm.value.type = "summary";
  await getService();
  await getTable();
  if (router.name === "UpdateCustomer") {
    await getCustomerSummary();
  }
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 v-if="router.name === 'CreateCustomer'">
          {{ $t('t-add-customer-info') }}
        </h3>
        <h3 v-else>{{$t('t-edit-customer-info')}}</h3>
      </div>
      <div class="card-body">
        <div class="main-body">
          <el-form
            ref="ruleFormRef"
            :model="dataForm.value"
            status-icon
            :rules="rulesForm"
            label-width="200px"
            class="form-user"
          >
            <el-form-item>
              <el-radio-group v-model="dataForm.value.type">
                <el-radio label="summary" style="margin-right: 90px">
                  Summary
                </el-radio>
                <el-radio label="session">Session</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item :label="$t('t-field-name')" prop="field_name">
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.field_name"
                @change="
                  dataForm.value.field_name = dataForm.value.field_name.trim()
                "
                :placeholder="$t('t-place-field-name')"
              />
            </el-form-item>

            <el-form-item :label="$t('t-service')" prop="service_id">
              <el-select
                v-model="dataForm.value.service_id"
                clearable
                :placeholder="$t('t-select-service')"
                style="width: 100%"
                @change="changeService"
                @clear="resetService"
              >
                <el-option
                  v-for="item in dataService.value"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('t-service-position')" prop="service_position">
              <el-input-number
                autocomplete="off"
                v-model="dataForm.value.service_position"
                @change="
                  dataForm.value.service_position =
                    dataForm.value.service_position.trim()
                "
                :placeholder="$t('t-place-service-position')"
                style="width: 100%"
                @wheel="$event.target.blur()"
              />
              <!-- <el-input
                type="number"
                autocomplete="off"
                v-model="dataForm.value.service_position"
                @change="dataForm.value.service_position = dataForm.value.service_position.trim()"
                placeholder="Nhập vị trí dịch vụ"
              /> -->
            </el-form-item>

            <el-form-item :label="$t('t-table-config-title_name')" prop="col_name">
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.col_name"
                @change="
                  dataForm.value.col_name = dataForm.value.col_name.trim()
                "
                :placeholder="$t('t-place-table-config-title_name')"
              />
            </el-form-item>

            <el-form-item :label="$t('t-index')" prop="col_position">
              <el-input-number
                autocomplete="off"
                v-model="dataForm.value.col_position"
                @wheel="$event.target.blur()"
                :placeholder="$t('t-place-index')"
                style="width: 100%"
              />
            </el-form-item>

            <el-form-item :label="$t('t-table-name')" prop="table_config_id">
              <el-select
                v-model="dataForm.value.table_config_id"
                clearable
                :placeholder="$t('t-select-table')"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dataTables.value"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('t-where-statement')"
              v-if="dataForm.value.type == 'session'"
            >
              <el-input
                type="text"
                autocomplete="off"
                v-model="dataForm.value.where_statement"
                :placeholder="$t('t-place-where-statement')"
              />
            </el-form-item>

            <el-form-item :label="$t('t-thresh-check')" prop="thresh_check">
              <el-select
                v-model="dataForm.value.thresh_check"
                clearable
                :placeholder="$t('t-place-thresh-check')"
                style="width: 100%"
              >
                <el-option
                  v-for="item in checkDisabled"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('visible_columns')" prop="disabled">
              <el-select
                v-model="dataForm.value.disabled"
                clearable
                :placeholder="$t('t-place-disabled-column')"
                style="width: 100%"
              >
                <el-option
                  v-for="item in checkDisabled"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('t-identity-field')" prop="is_identity">
              <el-select
                v-model="dataForm.value.is_identity"
                clearable
                :placeholder="$t('t-place-identity-field')"
                style="width: 100%"
              >
                <el-option
                  v-for="item in checkDisabled"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('t-search-enable')" prop="search_enable">
              <el-select
                v-model="dataForm.value.search_enable"
                clearable
                :placeholder="$t('t-place-search-enable')"
                style="width: 100%"
              >
                <el-option
                  v-for="item in checkDisabled"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item :label="$t('t-sort-enable')" prop="sortable">
              <el-select
                v-model="dataForm.value.sortable"
                clearable
                :placeholder="$t('t-place-sort-enable')"
                style="width: 100%"
              >
                <el-option
                  v-for="item in checkDisabled"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              :label="$t('t-default-sort')"
              prop="default_sort_type"
            >
              <el-input-number
                class="input-number"
                v-model="dataForm.value.default_sort_type"
                :placeholder="$t('t-place-default-sort')"
                style="width: 100%"
                @wheel="$event.target.blur()"
                @change="handleChangeInput"
              />
            </el-form-item>

            <div class="text-center">
              <button
                type="button"
                class="btn btn-soft-secondary btn-border mx-3"
                @click="routerPush.go(-1)"
              >
                {{ $t("t-back") }}
              </button>
              <el-button
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="addNewCustomer(ruleFormRef)"
                v-if="router.name === 'CreateCustomer'"
                :loading="loadingBtn"
              >
                {{ $t("t-add") }}
              </el-button>

              <el-button
                type="button"
                style="height: 38px"
                class="btn btn-secondary btn-border"
                @click="updateCustomer(ruleFormRef)"
                :loading="loadingBtn"
                v-else
              >
                {{ $t("t-update") }}
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.el-radio {
  margin-bottom: 0;
}
:deep .el-input__wrapper {
  padding-top: 2px;
}
</style>
