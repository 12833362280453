// import ValidService from "../../service/ValidService";

let model = {};

// dữ liệu form
model.dataForm = {
  id: undefined,
  service_id: undefined,
  service_position: undefined,
  col_name: undefined,
  col_position: undefined,
  field_name: undefined,
  table_config_id: undefined,
  thresh_check: undefined,
  where_statement: undefined,
  disabled: undefined,
  search_enable: undefined,
  type: undefined,
  is_identity: undefined,
  sortable: undefined,
  default_sort_type: undefined,
};

model.roles = [
  {
    value: "View",
    label: "View",
  },
  {
    value: "Edit",
    label: "Edit",
  },
];

model.tableRules = {
  lengthMenu: [10, 20, 50, 100],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 20,
  offset: 0,
  sort: "-type",
  defaultSort: ["-type", "descending"],
  fields:
    "id,type,service_id,service_position,col_name,col_position,field_name,table_config_id,thresh_check,disabled,search_enable,is_identity,sortable,default_sort_type",
  filters: "",
  dataSearch: {
    value: {
      col_name: "",
      field_name: "",
    },
    // valid: {
    //     // name: [ValidService.checkNameAllowVN],
    //     code: [ValidService.checkNumber],
    //     type: [ValidService.checkNameAllowVN],
    // },
    operator: {
      col_name: ":regex_i:",
      field_name: ":regex_i:",
      // type: ":regex_i:",
    },
  },
};

export default model;
