import i18n from "../i18n";

let ValidService = {};

ValidService.required = {
required: true,
message:i18n.global.t('valid_required'),
trigger: "blur",
};

ValidService.requiredChange = {
required: true,
message:i18n.global.t('valid_requiredChange'),
trigger: "change",
};

ValidService.requiredInputNumber = {
required: true,
message:i18n.global.t('valid_input_number_integer'),
trigger: "blur",
};

ValidService.checkAccount = {
pattern: /^[0-9a-zA-Z._@]+$/g,
message:i18n.global.t('valid_checkAccount'),
trigger: "blur",
};

ValidService.checkCode = {
pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/,
message:i18n.global.t('valid_checkCode'),
trigger: "blur",
};

ValidService.checkNumber = {
pattern: /^[0-9]+$/g,
message:i18n.global.t('valid_checkNumber'),
trigger: "blur",
};

ValidService.checkNumberInteger = {
pattern: /^[-*0-9]+$/g,
message:i18n.global.t('valid_checkNumber_integer'),
trigger: "blur",
};

ValidService.checkPhone = {
pattern: /^(0|\+?84|0084)\d{9}$/,
message:i18n.global.t('valid_checkPhone'),
trigger: "blur",
};

ValidService.checkPhone10_11Number = {
pattern: /^0[0-9]{9,10}$/,
message:i18n.global.t('valid_checkPhone10_11Number'),
trigger: "blur",
};

ValidService.checkSpaceString = {
pattern: /\S/g,
message:i18n.global.t('valid_checkSpaceString'),
trigger: "blur",
};

ValidService.checkNameAllowVN = {
pattern:
/^[0-9a-zA-Z_ ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìiíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+$/,
message:i18n.global.t('valid_checkNameAllowVN'),
trigger: "blur",
};

ValidService.checkMaxLength = (max) => {
return {
max: max,
message:i18n.global.t('valid_characterMax') + max,
trigger: "blur",
};
};

ValidService.checkMinLength = (min) => {
return {
min: min,
message:i18n.global.t('valid_characterMin') + min,
trigger: "blur",
};
};

ValidService.checkNumberFloatNotNegative = {
pattern: /^[\d.]+$/,
message:i18n.global.t('valid_checkNumberFloatNotNegative'),
trigger: "blur",
};

ValidService.checkNumberPositive = {
pattern: /^[-]?[\d]+$/,
message:i18n.global.t('valid_checkNumberInteger'),
trigger: "blur",
};

ValidService.number0100 = {
pattern: /^[0-9]{1,2}$/,
message:i18n.global.t('valid_number0100'),
trigger: "blur",
};

ValidService.checkEmail = {
pattern:
/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
message:i18n.global.t('valid_checkEmail'),
trigger: "blur",
};

ValidService.checkAz09 = {
pattern: /^[\w]+$/g,
message:i18n.global.t('t_a-z_0-9'),
trigger: "blur",
};

ValidService.checkURL = {
pattern:
/^(https?:)?\/\/((([a-z0-9-]+\.)+[a-z]{2,5})|(((1?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(1?\d{1,2}|2[0-4]\d|25[0-5])))(:\d{1,5})?($|\/|\?|#)/,
message:i18n.global.t('valid_checkUrl'),
trigger: "blur",
};

ValidService.checkServer = {
pattern: /^[a-z0-9-.]*$/,
message:i18n.global.t('valid_checkServer'),
trigger: "blur",
};

ValidService.checkDatabaseName = {
pattern: /^\w+$/,
message:i18n.global.t('valid_checkDatabaseName'),
trigger: "blur",
};

ValidService.checkSpecial = {
pattern: /^\w+$/,
message:i18n.global.t('valid_check_special'),
trigger: "blur",
};

ValidService.checkNoSpace = {
pattern: /^\S*$/,
message:i18n.global.t('valid_checkNoSpace'),
trigger: "blur",
};

ValidService.checkPolygonNoneHTML = {
pattern:
/^\[\[\[\d+(\.\d+)?,\d+(\.\d+)?\](,\[\d+(\.\d+)?,\d+(\.\d+)?\]){2,}\](,\[\[\d+(\.\d+)?,\d+(\.\d+)?\](,\[\d+(\.\d+)?,\d+(\.\d+)?\]){2,}\])*\]$/,
message:i18n.global.t('t_wrong_polygon'),
trigger: "blur",
};

ValidService.checkPolygonWithHTML = {
pattern:
/^<MultiGeometry>(<Polygon><outerBoundaryIs><LinearRing><coordinates>\d+(\.\d+)?,\d+(\.\d+)?( \d+(\.\d+)?,\d+(\.\d+)?){2,}<\/coordinates><\/LinearRing><\/outerBoundaryIs><\/Polygon>)+<\/MultiGeometry>$/,
message: i18n.global.t('t_wrong_polygon_html'),
trigger: "blur",
};

ValidService.sql = {
pattern: "@table",
message: i18n.global.t('t-place-sql'),
trigger: "blur",
};

export default ValidService;

